.study-browser {
  float: left;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #000;
  padding-bottom: 20px;
  padding-top: 10px;
}
.study-browser .scrollable-study-thumbnails {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 50px;
  padding-right: 16px;
  padding-left: 4px;
  margin-right: -16px;
  display: flex;
  flex-direction: column;
}
.study-browser .scrollable-study-thumbnails::-webkit-scrollbar {
  display: none;
}
.study-browser .scrollable-study-thumbnails .thumbnail-container {
  margin: 0 auto;
  padding-bottom: 0.25rem;
}
.study-browser .scrollable-study-thumbnails .thumbnail-container .noselect {
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
.draggable {
  cursor: copy;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.container:after {
  background-color: #3c5d80 /* Set the background color of the scrollbar */;
}
.content {
  height: 100%;
  overflow-y: scroll;
/* Custom scrollbar style */
}
.content::-webkit-scrollbar {
  width: 8px /* Set the width for the scrollbar */;
}
.content::-webkit-scrollbar-thumb {
  background-color: #3c5d80 /* Set the color of the scrollbar thumb */;
  border-radius: 5px /* Set the radius of the scrollbar thumb */;
}
.content::-webkit-scrollbar-thumb:hover {
  background-color: #20a5d6 /* Set the color of the scrollbar thumb on hover */;
}
.content:hover {
  cursor: pointer;
}
.content:hover .thumbnail-container {
  margin: 0 auto;
  padding-bottom: 0.25rem;
}
.content:hover .thumbnail-container .noselect {
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
